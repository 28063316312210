body,
html {
    font-family: 'Open Sans', sans-serif;
    margin: 0px;
}

// ::-webkit-scrollbar {
//     width: 15px;
//     height: 15px;
// }
button,
.pointer {
    cursor: pointer;
}
h1,
h2,
h3 {
    color: rgba(0, 0, 0, 0.65) !important;
}
.uppercase {
    text-transform: uppercase;
}
.ml {
    margin-left: 8px !important;
}
.ml-sm {
    margin-left: 5px !important;
}
.mr {
    margin-right: 8px !important;
}
.mt {
    margin-top: 8px !important;
}
.mt-2 {
    margin-top: 16px !important;
}
.mb {
    margin-bottom: 8px !important;
}
.pl {
    padding-left: 8px !important;
}
.pr {
    padding-right: 8px !important;
}
.pt {
    padding-top: 8px !important;
}
.pb {
    padding-bottom: 8px !important;
}
.mb-sm {
    margin-bottom: 4px;
}
.fw {
    width: 100%;
}
.icon-only-btn {
    width: 20px !important;
}
.dp-none {
    display: none;
}

.ratingContainer {
    border: 1px solid rgb(109, 196, 231);
    padding-left: 10px;
    padding-top: 5px;
    height: 35px;
    border-radius: 5px;
    display: flex;
    width: 125px;
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 10px;

    img {
        margin-top: 4px;
        margin-right: 5px;
        width: 13px;
        height: 13px;
    }
}
.text-center {
    text-align: center;
}
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.float-left {
    float: left;
}
.float-right {
    float: right;
}
.user-email {
    font-family: 'Euclid Square ';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.06px;
}
.clear {
    clear: both;
}
.f-16 {
    font-size: 16px;
}
.font-normal {
    font-weight: normal;
}
.space-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0.5rem 0;

    // border: 1px solid blue;
    // justify-content: center;
}
.team-btn-container {
    display: flex;
    justify-content: space-between;
}
.dib {
    display: inline-block;
}
.pageLayout {
    min-height: 100vh;
    width: 100vw;
}

.left-panel {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 5px;
}
.FormWrapper {
    padding: 60px 20px;
    max-width: 350px;
    margin: 20px auto;
}
.PageProductSection {
    border-left: 1px solid black;
    min-height: 100vh;
    background: url('/img/download.jpeg');
    background-position: center;
    background-size: cover;
    background-repeat: repeat-x;
    -webkit-animation: backgroundScroll 1500s linear 1;
    -webkit-animation-fill-mode: forwards;
    animation: backgroundScroll 1500s linear 1;
    animation-fill-mode: forwards;
}

@keyframes backgroundScroll {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -48000px 0px;
    }
}
.login-form {
    max-width: 300px;
}
.viewport-height {
    height: 100vh;
}
.overflow-auto {
    overflow: auto;
}
.container {
    // border-radius: 5px;
    padding: 0px 10px;
    height: calc(100vh - 80px);
}
.header {
    padding: 3px 0px;
    display: inline-block;
    width: 100%;
    background-color: white;
    border-bottom: 1px solid #eee;
}
.header > div {
    .dib;
}

.secondary-header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}

.nav-img {
    width: 10%;
}

.logo-img {
    width: 150px;
}

.secondary-header img {
    width: 90%;
}

.nav-links {
    flex-grow: 2;
    display: flex;
    justify-content: center;
}

.nav-user-details {
    display: flex;
    align-items: center;
}

.logout-btn {
    float: right;
}

.logout-icon {
    font-size: 14px;
    color: #4361ee;
    display: flex;
    width: 18px;
    height: 18px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-top: 2px;
}
.group-icon {
    font-size: 14px;
    color: #4361ee;
    display: flex;
    width: 18px;
    height: 18px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-top: 6px;
}

#map {
    width: 100%;
    height: calc(100vh - 60px);
    border: 1px solid #ddd;
    margin-top: 5px;
}
.selected-layer {
    background: #71bbff;
}
.legend-entry {
    position: relative;
    display: inline;
    margin-left: 5px;
}
.legend-layer {
    cursor: pointer;
    height: 25px;
}
.legend-line {
    vertical-align: middle;
    height: 16px; // Numbers based on SVG Line Coordinates
    width: 30px;
}
.tip {
    font-size: 11px;
}
.active-tool,
.active-tool:focus {
    border: 4px solid #00f !important;
    box-shadow: 1px 1px 4px #aaa inset !important;
    background: #eee !important;
}

.inactive-tool:disabled {
    background: #cccccc !important;
}
.blink {
    animation: 1s linear infinite blink;
    font-weight: bold;
}
@keyframes blink {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.remaining-time {
    font-size: 16px;
    user-select: none;
    display: flex;
    width: 190px;
    height: 31px;
    padding: 3px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: -1px;
    flex-shrink: 0;
    margin-left: 9px;
}
.break {
    font-size: 14px;
    padding: 3px;
}
#select-attr,
#layers-dropdown {
    width: max-content;
    border: 1px solid #aaa;
    background: #eee;
    position: fixed;
    display: none;
    padding: 5px;
    overflow: hidden;
    max-height: 110px;
    border-radius: 3px;
}
#layers-dropdown {
    overflow-y: scroll;
}
#select-attr,
#layers-dropdown option {
    padding: 4px 5px;
}
.waiting-icon {
    margin-left: 10px;
    width: 40px;
    object-fit: contain;
}
.break-icon {
    margin-left: 10px;
    margin-right: 5px;
    width: 30px;
    object-fit: contain;
}
.shortcut-keys-container > span {
    display: inline-block;
    border: 1px solid #000;
    text-align: center;
    font-size: x-small;
    font-weight: bold;
    padding: 3px;
    width: 90%;
    .mt;
}

.feedback-container {
    // margin: 1rem 0;
    display: flex;
    width: 99px;
    padding: 8px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-left: 36px;
    border-radius: 4px;
}
.fix-button {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid rgb(160, 175, 246);
    color: white;
    background-color: #1890ff;
}
.management-nav {
    padding: 10px 15px;
    color: rgba(0, 0, 0, 0.65);
}
.management-nav-active {
    font-weight: bold !important;
    color: #1890ff !important;
    border-bottom: 2px solid #1890ff !important;
}
.ant-badge-status-dot {
    height: 8px;
    width: 8px;
}
.layer-box {
    position: absolute;
    top: 0;
    right: 0;
    background: rgb(255, 255, 255);
    padding: 10px;
    border: 1px solid #aaa;
    max-width: 550px;
}
.relative {
    position: relative;
}
.job-id-map {
    font-size: small;
    margin-right: 5px;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid black;
}
.job-id-map-entry {
    margin-bottom: 5px;
}
hr {
    height: '1px';
    border: '1px solid gray';
    background-color: 'gray';
    margin: 0px 5px 0px 5px;
}
.falcon-qa-rate-panel {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 10px;
    border: 1px solid black;
}
.display-id-container {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 10px;
    border: 1px solid black;
}

.lawnx-layer-color-table {
    display: flex;
    flex: 1;
    width: 100%;
    column-gap: 5px;
    margin-bottom: 5px;
    div {
        flex: 1;
        border-radius: 2px;
        padding: 4px;
        text-align: center;
    }
}

.lawnx-layer-color-table table {
    width: 100%;
}
.lawnx-layer-color-table td {
    padding: '2px 5px';
}
.font-bold {
    font-weight: 700;
    color: black !important;
}
.card-design {
    margin: auto;
    background-color: #698474;
    // border: 1px solid #9e9fab;
    margin-top: 30px;
    width: '500px';
}
.text-light {
    color: white !important;
}
.data-header {
    padding: 8px 20px;
    display: inline-block;
    width: 100%;
    background-color: white;
    border-bottom: 1px solid #eee;
}
.data-platform {
    margin: 0px -10px;
    width: 100vw;
    height: 100vh;
    background-color: #e4efe7;
    overflow: scroll;
}
.layer-opacity-slider-container {
    margin-top: 10px;
    border-top: 2px solid black;
}
.layer-opacity-slider-container > .ant-slider {
    margin-top: 5px !important;
}
.ant-slider-step {
    background-color: #cccccc;
    height: 8px;
}
.ant-slider-track {
    background-color: blue !important;
    height: 8px;
    z-index: 1;
}
.ant-slider-handle {
    width: 16px;
    height: 16px;
    z-index: 2;
}
.refresh-icon {
    float: right;
    margin-left: auto;
    margin-right: 5px;
}

/* Upload Platform */
.up-mtb {
    margin: 1rem 0;
}

.up-mlr {
    margin: 0 1rem;
}

.up-div-span {
    display: flex;
    justify-content: space-between;
}

.up-display-flex {
    display: flex;
    column-gap: 1rem;
}

.up-divider {
    height: 100%;
}

.up-paragraph {
    margin-top: 0.6rem;
}

.up-downloadInputBtn {
    text-decoration: none;
    padding-left: 0.2rem;
}

.up-emailTextArea {
    height: 30vh !important;
}

.up-space {
    width: 100%;
}

.dropzoneBaseStyle {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    border-width: 2;
    border-radius: 2;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
}

.feedback_file_input {
    cursor: pointer;
    padding: 5px;
    margin: 1rem 0;
    color: #1890ff;
    border: 1px solid #1890ff;
    width: fit-content;
}

.toolbox-container {
    display: flex;
    justify-content: space-between;
}

.shortcut-box {
    padding: 7px;
    width: 100%;
    font-weight: bold;
    margin-top: 30px;
}
.link-style {
    color: #1890ff;
    cursor: pointer;
}

.action-col-items {
    display: flex;
    // justify-content: space-between;
    column-gap: 10px;
}

.leaderboard-style {
    text-decoration: underline;
}

.property-type-dropdown {
    width: 200px;
    border-radius: 4px;
    border: 1px solid var(--primary-black-40-bdbdbd, #bdbdbd);
    outline: none;
    color: #1890ff !important;
}

.property-type-dropdown::placeholder {
    color: #1890ff !important;
}
.up-upload-file-btn {
    cursor: pointer;
    margin: 2rem 0 1rem 0;
    padding: 0.5rem 2rem;
    border: 1px solid #1890ff;
    color: #1890ff;
    border-radius: 2px;
}

.up-upload-file-btn::file-selector-button {
    border: none;
    border: 1px solid #1890ff;
    background-color: inherit;
    color: #1890ff;
    cursor: pointer;
}

.worksheet-files {
    display: flex;
    column-gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.worksheet-files-title {
    width: 85%;
}

.worksheet-modal-footer {
    display: flex;
    justify-content: center;
    align-items: center;
}

#scale-input {
    display: flex;
    column-gap: 1rem;
    align-items: center;
}

#scale-input span {
    font-size: 0.9rem;
    font-weight: 700;
}

#scale-input input {
    border: none;
    border-bottom: 1px solid #d9d9d9;
    outline: none;
    font-weight: 700;
    width: 220px;
}

#scale-input .dropdown-select {
    width: 250px;
}

.text-bold {
    font-weight: 700;
}

.sliderContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.sliderContainer img {
    width: 100%;
    height: 700px;
}

.obliqueImageryContainer {
    margin: auto;
    padding: 1rem;
    width: 900px;
}

.cursor-grab {
    cursor: grab;
}

.error-msg {
    color: red;
}
.download-visual-output {
    text-align: center;
    margin-top: 1rem;
}

.parcelLayerOpacity {
    margin-top: 8px !important;
    display: flex;
}

.input-id-details {
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

.small-margin-right {
    margin-right: 1rem;
}

.ant-input-number-input {
    text-align: center;
}
.warning-msg {
    display: flex;
    flex-direction: column;
}
.decrease-value {
    // backgroundColor: '#7389F2',
    //                     color: 'white',
    //                     borderTopLeftRadius: '4px',
    //                     borderBottomLeftRadius: '4px',
    //                     borderTopRightRadius: '0px',
    //                     borderBottomRightRadius: '0px'
    background-color: '#7389F2';
    color: 'white';
    border-radius: 0px, 4px, 4px, 0px;
}

@media (max-width: 992) {
    .lawnx-layer-color-table td {
        font-size: small;
    }
}
@media (max-height: 800px) {
    .job-id-map {
        max-height: 200px;
    }
}

@media (max-height: 900px) {
    .falcon-qa-rate-panel {
        max-height: 200px;
    }
    // .display-id-container {
    //     max-height: 200px;
    // }
}
.originId {
    display: flex;
    width: 230px;
    height: 100px;
    padding: 18px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid var(--secondary-blue-0-f-6-f-7-fe, #f6f7fe);
    background: var(--White, #fff);

    /* Light_card */
    box-shadow: 0px 1px 3px 1px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.text-white {
    color: 'white';
}

.tooltip-menu {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    display: none;
    z-index: 10;
    font-size: 12px;
    padding: 10px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
}
